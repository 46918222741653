import React from "react";
import CoreValues from "../components/subComponents/CoreValues";
import coreValuesList from "../coreValueList";
import Contact from "../components/Contact";
import Team from "../components/subComponents/team";
import teamList from "../teamList";
import useIntersectionObserver from "../hooks/useIntersectionObserver";

import gcasConstitution from "../assets/gcas-constitution.pdf";

function AboutUs() {
	const sectionsRef = useIntersectionObserver(
		(target) => {
			target.classList.add("visible");
		},
		{ threshold: 0.1 }
	);

	return (
		<section className="aboutUsContainer">
			<article
				ref={(el) => (sectionsRef.current[0] = el)}
				className="desktopMaxWidth hero aboutHero"
			>
				<div className="aboutHeroContainer">
					<h3 className="aboutHeroTitle">Who We Are</h3>
					<p className="aboutHeroText">
						Established under the Constitution of the Ghanaian-Canadian
						Association of Saskatchewan, 1989, We strive to create a supportive
						environment for all members to thrive.
					</p>
					<a
						href={gcasConstitution}
						className="ctaSecondary constitutionBtn"
						target="_blank"
						rel="noopener noreferrer"
					>
						Read Our Constitution
						<span>
							<i className="fa-solid fa-book"></i>
						</span>
					</a>
				</div>
			</article>

			<article
				ref={(el) => (sectionsRef.current[1] = el)}
				className="desktopMaxWidth aboutCard"
			>
				<div className="aboutCardLeftPane">
					<p className="tag aboutCardTag">About</p>
					<p className="aboutCardTagline">
						Dedicated to fostering a strong sense of unity
					</p>
				</div>
				<div className="aboutCardRightPane">
					<p className="intro">
						GCAS is open to all individuals within the Ghanaian community and
						friends of Ghana.
					</p>
					<p className="rightPaneText">
						We welcome members from diverse backgrounds, including non-Ghanaian
						spouses and individuals who share an interest in Ghanaian culture.
						We encourage rich cultural exchanges, and offering unwavering
						support to both Ghanaians and Canadians residing in Saskatchewan.
					</p>
				</div>
			</article>

			<article
				ref={(el) => (sectionsRef.current[2] = el)}
				className="desktopMaxWidth coreValuesContainer"
			>
				{coreValuesList.map((coreValue, index) => (
					<CoreValues
						key={coreValue.title}
						title={coreValue.title}
						info={coreValue.info}
						img={coreValue.img}
						reverse={index % 2 !== 0}
					/>
				))}
			</article>

			<article
				ref={(el) => (sectionsRef.current[3] = el)}
				className="desktopMaxWidth teamContainer"
			>
				<p className="tag ourTeamTag">Our Team</p>
				<div className="teamCards">
					{teamList.map((teamMember) => (
						<Team
							key={teamMember.position}
							name={teamMember.name}
							position={teamMember.position}
							profileImg={teamMember.profileImg}
						/>
					))}
				</div>
			</article>
			<Contact />
		</section>
	);
}

export default AboutUs;

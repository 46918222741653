import React from "react";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";

function CoreValues({ title, info, img, reverse }) {
	const sectionsRef = useIntersectionObserver(
		(target) => {
			target.classList.add("visible");
		},
		{ threshold: 0.1 }
	);

	return (
		<article
			ref={(el) => (sectionsRef.current[0] = el)} // Ensure proper ref indexing
			className={`coreValueContainer ${reverse ? "reverse" : ""}`}
		>
			<div className="coreValueImgContainer">
				<img src={img} alt={title} />
			</div>
			<div className="coreValueTexts">
				<h2 className="coreValueTitle">{title}</h2>
				<p className="coreValueInfo">{info}</p>
			</div>
		</article>
	);
}

export default CoreValues;

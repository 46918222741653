import { useEffect, useRef } from "react";

const useIntersectionObserver = (callback, options) => {
	const elementsRef = useRef([]);

	useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					callback(entry.target);
				}
			});
		}, options);

		const currentElements = elementsRef.current;
		currentElements.forEach((element) => {
			if (element) observer.observe(element);
		});

		return () => {
			currentElements.forEach((element) => {
				if (element) observer.unobserve(element);
			});
		};
	}, [callback, options]);

	return elementsRef;
};

export default useIntersectionObserver;

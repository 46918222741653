import { createClient } from "@sanity/client";

const client = createClient({
	projectId: "n5fh1js2",
	dataset: "production",
	apiVersion: "2024-06-21", // Use a recent ISO date string
	useCdn: true,
});

export default client;

import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import client from "../sanity/client";
import EventCard from "../components/subComponents/eventCard";
import Contact from "../components/Contact";
import { helix, ring } from "ldrs";

const Events = () => {
	const [events, setEvents] = useState([]);
	const [loading, setLoading] = useState(true);

	const sectionsRef = useRef([]);

	ring.register();
	helix.register();

	useEffect(() => {
		const currentSections = sectionsRef.current;

		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						entry.target.classList.add("visible");
					}
				});
			},
			{ threshold: 0.1 } // Adjust the threshold as needed
		);

		currentSections.forEach((section) => {
			if (section) observer.observe(section);
		});

		return () => {
			currentSections.forEach((section) => {
				if (section) observer.unobserve(section);
			});
		};
	}, []);

	useEffect(() => {
		const fetchEvents = async () => {
			try {
				const data = await client.fetch(
					`*[_type == "event" && date >= now()] | order(date asc) {
            _id,
            name,
			headline,
            description,
            date,
            location,
            "image": image.asset->url
          }`
				);
				setEvents(data);
				setLoading(false);
			} catch (error) {
				console.error("Error fetching events:", error);
				setLoading(false);
			}
		};

		fetchEvents();
	}, []);

	if (loading) {
		return (
			<div className="loadingAnimationContainer">
				<l-ring
					size="40"
					stroke="5"
					bg-opacity="0"
					speed="2"
					color="black"
				></l-ring>
			</div>
		);
	}

	if (events.length === 0) {
		return (
			<section
				ref={(el) => (sectionsRef.current[0] = el)}
				className="allEventsContainer animate"
			>
				<section className="eventSection">
					<div className="desktopMaxWidth eventHeader noEventsContainer">
						<div className="loadingAnimationContainer">
							<l-helix size="100" speed="4" color="#fff"></l-helix>
						</div>
						<div className="noEventsContainer">
							<p className="noUpcomingEventsText">Oops, No Events Found!</p>
							<Link to="/">
								<button aria-label="Back to Home" className="cta ">
									<i className="fa-solid fa-circle-chevron-left"></i>Back to
									Home
								</button>
							</Link>
						</div>
					</div>
				</section>
				<Contact />
			</section>
		);
	}

	return (
		<section
			ref={(el) => (sectionsRef.current[0] = el)}
			className="allEventsContainer animate"
		>
			<section className="eventSection">
				<div className="desktopMaxWidth eventHeader">
					<p className="upcomingEventsText">All Events</p>
				</div>
				<div className="desktopMaxWidth eventCards">
					{events.map((event) => (
						<EventCard event={event} key={event._id} />
					))}
				</div>
			</section>
			<Contact />
		</section>
	);
};

export default Events;

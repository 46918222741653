// src/pages/NotFound.js
import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
	return (
		<div className="notFound">
			<dotlottie-player
				src="https://lottie.host/505097cc-8bad-4bec-a986-1a0517e5aa9c/i3gKfhVRhB.lottie"
				background="transparent"
				speed="1"
				style={{ width: "300px", height: "300px" }}
				loop
				autoplay
			></dotlottie-player>
			<Link to="/">
				<button aria-label="Back to Home" className="cta ">
					<i className="fa-solid fa-circle-chevron-left"></i>Back to Home
				</button>
			</Link>
		</div>
	);
};

export default NotFound;

import React, { useEffect, useState } from "react";
import client from "../sanity/client";
import EventCard from "../components/subComponents/eventCard";
import { Link } from "react-router-dom";
import { helix, ring } from "ldrs";

const Events = () => {
	const [events, setEvents] = useState([]);
	const [loading, setLoading] = useState(true);
	ring.register();
	helix.register();

	useEffect(() => {
		const fetchEvents = async () => {
			try {
				const data = await client.fetch(
					`*[_type == "event" && date >= now()] | order(date asc) [0...2] {
            _id,
            name,
			headline,
            description,
            date,
            location,
            "image": image.asset->url
          }`
				);
				setEvents(data);
				setLoading(false);
			} catch (error) {
				console.error("Error fetching events:", error);
				setLoading(false);
			}
		};

		fetchEvents();
	}, []);

	if (loading) {
		return (
			<section className="eventSection">
				<div className="desktopMaxWidth eventHeader ifLoading">
					<p className="upcomingEventsText">Upcoming Events</p>
					<Link to="/events"></Link>
					<div className="loadingAnimationContainer">
						<l-ring
							size="40"
							stroke="5"
							bg-opacity="0"
							speed="2"
							color="white"
						></l-ring>
					</div>
				</div>
			</section>
		);
	}

	if (events.length === 0) {
		return (
			<section className="eventSection">
				<div className="desktopMaxWidth eventHeader">
					<p className="upcomingEventsText">Upcoming Events</p>
				</div>
				<div className="loadingAnimationContainer">
					<l-helix size="100" speed="4" color="#fff"></l-helix>
				</div>

				<p className="noUpcomingEventsText">
					Oops, There are no upcoming events!
				</p>
			</section>
		);
	}

	return (
		<section className="eventSection">
			<div className="desktopMaxWidth eventHeader">
				<p className="upcomingEventsText">Upcoming Events</p>
				<Link to="/events">
					<button
						aria-label="See All Events"
						className="cta ctaSecondary allEventsCta"
					>
						See All Events
					</button>
				</Link>
			</div>
			<div className="desktopMaxWidth eventCards">
				{events.map((event) => (
					<EventCard event={event} key={event._id} />
				))}
			</div>
		</section>
	);
};

export default Events;

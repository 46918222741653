// src/App.js
import { Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Events from "./pages/Events";
import EventDetail from "./components/EventDetail";
import NotFound from "./pages/NotFound";
import Footer from "./components/Footer";

function App() {
	return (
		<div className="appContainer">
			<Header />
			<main className="mainContent">
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/about" element={<AboutUs />} />
					<Route path="/events" element={<Events />} />
					<Route path="/events/:id" element={<EventDetail />} />
					<Route path="*" element={<NotFound />} /> {/* Catch-all route */}
				</Routes>
			</main>
			<Footer />
		</div>
	);
}

export default App;

function Map() {
	return (
		<div>
			<iframe
				title="Google Maps Location of Regina Downtown"
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7303.204828144479!2d-104.61745560748008!3d50.45012806670345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x531c1e43fb080369%3A0x1ed8eec171e3de93!2sRegina%20Downtown!5e0!3m2!1sen!2sca!4v1716616162110!5m2!1sen!2sca"
				width="100%"
				height="320px"
				style={{ border: 0 }}
				loading="lazy"
				referrerPolicy="no-referrer-when-downgrade"
			></iframe>
		</div>
	);
}

export default Map;

//
// For future when 3rd party cookies are blocked

// function Map() {
// 	const apiKey = "AIzaSyAdilGGw7SjMAPlYUxzj-z7OdmVcmyaT3c";
// 	const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=Regina,SK&zoom=14&size=600x300&maptype=roadmap&key=${apiKey}`;

// 	return (
// 		<div className="mapContainer">
// 			<img
// 				className="map"
// 				src={mapUrl}
// 				alt="Google Map of Regina Downtown"
// 				width="100%"
// 				height="100%"
// 				style={{ border: 0 }}
// 			/>
// 		</div>
// 	);
// }

// export default Map;

import { NavLink, useMatch, useResolvedPath } from "react-router-dom";
import { Link } from "react-scroll";
import gcasLogo from "../assets/images/gcas-logo.svg";
import { useState, useEffect } from "react";

function Header() {
	const [isOpen, setIsOpen] = useState(false);

	const toggleMenu = () => {
		setIsOpen((open) => !open);
	};

	const closeMenu = () => {
		setIsOpen(false);
	};

	useEffect(() => {
		if (isOpen) {
			document.body.classList.add("no-scroll");
		} else {
			document.body.classList.remove("no-scroll");
		}
	}, [isOpen]);

	return (
		<header>
			<nav className={`navBar mobileWidth ${isOpen ? "isOpen" : ""}`}>
				<div className="mobileHeaderOnly">
					<NavLink className="link logo headerLogo" to="/" onClick={closeMenu}>
						<img alt="GCAS Regina" src={gcasLogo} />
					</NavLink>
					<button
						aria-label="Toggle Menu for Navigation"
						className={`hamburger ${isOpen ? "isOpen" : ""}`}
						onClick={toggleMenu}
					>
						<i className="fa-solid fa-bars-staggered"></i>
					</button>
				</div>

				<ul className={`navItems ${isOpen ? "isOpen" : ""}`}>
					<CustomLink to="/" onClick={closeMenu}>
						Home
					</CustomLink>
					<CustomLink to="/about" onClick={closeMenu}>
						About Us
					</CustomLink>
					<CustomLink to="/events" onClick={closeMenu}>
						Events
					</CustomLink>
					<li>
						<Link
							spy={true}
							smooth={true}
							offset={-100}
							duration={500}
							className="navItemAlt"
							href="/"
							to="contact"
							onClick={closeMenu}
						>
							Contact Us
						</Link>
					</li>
					<button
						aria-label="Join WhatsApp"
						className={`cta navBtn joinCommunity ${isOpen ? "isOpen" : ""}`}
					>
						Join on WhatsApp
						<span>
							<i className="fa-brands fa-whatsapp"></i>
						</span>
					</button>
				</ul>
			</nav>
		</header>
	);
}

function CustomLink({ to, children, onClick, ...props }) {
	const resolvedPath = useResolvedPath(to);
	const isActive = useMatch({ path: resolvedPath.pathname, end: true });

	return (
		<li className={isActive ? "active" : ""}>
			<NavLink className="navItem" to={to} onClick={onClick} {...props}>
				{children}
			</NavLink>
		</li>
	);
}

export default Header;

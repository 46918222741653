import ContactCard from "./subComponents/contactCard";
import ContactForm from "./subComponents/contactForm";
import Map from "./subComponents/map";
function Contact() {
	return (
		<section id="contact" className="desktopMaxWidth contactSection">
			<div className="leftPane">
				<ContactCard />
				<Map />
			</div>
			<ContactForm />
		</section>
	);
}

export default Contact;

import React, { useState } from "react";

const ContactForm = () => {
	const [formData, setFormData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		message: "",
	});

	const [submissionStatus, setSubmissionStatus] = useState("");

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleFocus = () => {
		setSubmissionStatus("");
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const formEndpoint = "https://formspree.io/f/xyzgylee";

		try {
			const response = await fetch(formEndpoint, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(formData),
			});

			if (response.ok) {
				setSubmissionStatus("Success");
				setFormData({
					firstName: "",
					lastName: "",
					email: "",
					message: "",
				});
			} else {
				setSubmissionStatus("Error");
			}
		} catch (error) {
			console.error("Error submitting form:", error);
			setSubmissionStatus("Error");
		}
	};

	return (
		<form className="contactForm" onSubmit={handleSubmit}>
			<div className="formHeader">
				<p className="heading3">Reach Out to Us.</p>
				<p className="contactFormText">
					Have questions or feedback? We're here to help. Send us a message and
					we'll respond within 24 hours.
				</p>
				<hr />
			</div>
			<div className="formGroupContainer">
				<div className="formGroup">
					<div className="nameInputFields">
						<div>
							<label htmlFor="firstName">First Name</label>
							<input
								id="firstName"
								type="text"
								name="firstName"
								placeholder="Enter your first name"
								value={formData.firstName}
								onChange={handleChange}
								onFocus={handleFocus}
								required
								autoComplete="given-name"
							/>
						</div>
						<div>
							<label htmlFor="lastName">Last Name</label>
							<input
								id="lastName"
								type="text"
								name="lastName"
								placeholder="Enter your last name"
								value={formData.lastName}
								onChange={handleChange}
								onFocus={handleFocus}
								required
								autoComplete="family-name"
							/>
						</div>
					</div>
				</div>
				<div className="formGroup">
					<label htmlFor="email">Email Address</label>
					<input
						id="email"
						type="email"
						name="email"
						placeholder="Enter your email address"
						value={formData.email}
						onChange={handleChange}
						onFocus={handleFocus}
						required
						autoComplete="email"
					/>
				</div>
				<div className="formGroup">
					<label htmlFor="message">Message</label>
					<textarea
						id="message"
						name="message"
						placeholder="Leave us a message"
						value={formData.message}
						onChange={handleChange}
						onFocus={handleFocus}
						required
						autoComplete="off"
					></textarea>
				</div>
			</div>

			<button
				aria-label="Submit Form Message"
				className="cta formBtn"
				type="submit"
			>
				Send Message
			</button>
			<p className="privacyText">
				By contacting us, you agree to our <a href="/terms">Terms of Service</a>{" "}
				and <a href="/privacy">Privacy Policy</a>
			</p>
			{submissionStatus && (
				<div className={`submissionStatusContainer visible`}>
					<div className={`submissionStatus ${submissionStatus.toLowerCase()}`}>
						{submissionStatus === "Success" ? (
							<div className="successMessage">
								<dotlottie-player
									src="https://lottie.host/783d72f2-304e-4ee2-b89a-9c189f8bd009/yhfk2eL1in.json"
									speed="1"
									style={{ width: "5rem", height: "5rem" }}
									loop
									autoplay
								></dotlottie-player>
								<p>Your message has been sent.</p>
							</div>
						) : (
							<div className="errorMessage">
								<dotlottie-player
									src="https://lottie.host/783d72f2-304e-4ee2-b89a-9c189f8bd009/yhfk2eL1in.json"
									speed="1"
									style={{ width: "5rem", height: "5rem" }}
									loop
									autoplay
								></dotlottie-player>
								<p>Oops! Something went wrong. Please try again.</p>
							</div>
						)}
					</div>
				</div>
			)}
		</form>
	);
};

export default ContactForm;

import profile1 from "./assets/images/profile1.png";
import profile2 from "./assets/images/profile2.png";

import profile4 from "./assets/images/profile4.png";
const teamList = [
	{
		name: "Dorothy Sarsah",
		position: "President",
		profileImg: profile1,
	},
	{
		name: "Father J.B Okai",
		position: "Vice President",
		profileImg: profile2,
	},
	{
		name: "Gloria Bansah",
		position: "Treasurer",
		profileImg: profile4,
	},
];

export default teamList;

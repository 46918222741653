import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import client from "../sanity/client";
import { PortableText } from "@portabletext/react";
import { Link } from "react-router-dom";

const EventDetail = () => {
	const { id } = useParams();
	const [event, setEvent] = useState(null);
	const [loading, setLoading] = useState(true);
	const [timeLeft, setTimeLeft] = useState({});

	useEffect(() => {
		const fetchEvent = async () => {
			try {
				const data = await client.fetch(
					`*[_type == "event" && _id == $id]{
						_id,
						name,
						slug,
						eventType,
						date,
						doorsOpen,
						venue->{
							name,
							address
						},
						speaker->{
							name,
							bio
						},
						"image": image.asset->url,
						headline,
						description,
						tickets
					}[0]`,
					{ id }
				);
				setEvent(data);
				setLoading(false);
			} catch (error) {
				console.error("Error fetching event:", error);
				setLoading(false);
			}
		};

		fetchEvent();
	}, [id]);

	useEffect(() => {
		if (event) {
			const calculateTimeLeft = () => {
				const eventDate = new Date(event.date);
				const now = new Date();
				const difference = eventDate - now;

				let timeLeft = {};

				if (difference > 0) {
					timeLeft = {
						days: Math.floor(difference / (1000 * 60 * 60 * 24)),
						hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
						minutes: Math.floor((difference / 1000 / 60) % 60),
						seconds: Math.floor((difference / 1000) % 60),
					};
				} else {
					timeLeft = {
						days: 0,
						hours: 0,
						minutes: 0,
						seconds: 0,
					};
				}

				return timeLeft;
			};

			const intervalId = setInterval(() => {
				setTimeLeft(calculateTimeLeft());
			}, 1000);

			return () => clearInterval(intervalId);
		}
	}, [event]);

	if (loading) {
		return (
			<div className="loadingAnimationContainer">
				<l-ring
					size="40"
					stroke="5"
					bg-opacity="0"
					speed="2"
					color="black"
				></l-ring>
			</div>
		);
	}

	if (!event) {
		return <div>Event not found</div>;
	}

	return (
		<>
			<div className="desktopMaxWidth eventDetailsBackBtn">
				<Link to="/events">
					<button aria-label="Back" className="cta ">
						<i className="fa-solid fa-circle-chevron-left"></i>Back
					</button>
				</Link>
			</div>

			<div className=" desktopMaxWidth allEventDetailsContainer">
				<div className="eventDetailsImgContainer">
					{event.image && (
						<img src={event.image} alt={`${event.name} Poster`} />
					)}
				</div>
				<div className="eventDetails">
					<div>
						<h2>{event.name.toUpperCase()}</h2>
						{event.headline && (
							<div className="eventHeadline">
								<PortableText value={event.headline} />
							</div>
						)}
					</div>
					<div>
						{event.description && (
							<div className="eventDescription">
								<PortableText value={event.description} />
							</div>
						)}
					</div>

					<div>
						<p className="eventIconText">
							<span>
								<i className="fa-solid fa-calendar-days"></i>
							</span>
							Date
						</p>
						<p>
							{new Date(event.date).toLocaleDateString("en-GB", {
								day: "numeric",
								month: "long",
								year: "numeric",
							})}
						</p>
					</div>

					<div>
						<p className="eventIconText">
							<span>
								<i className="fa-solid fa-circle-exclamation"></i>
							</span>
							Type
						</p>
						<p>{event.eventType}</p>
					</div>

					<div>
						<p className="eventIconText">
							<span>
								<i className="fa-solid fa-door-open"></i>
							</span>
							Doors Open
						</p>
						<p>{event.doorsOpen} minutes before</p>
					</div>
					<div>
						{event.venue && (
							<div>
								<p className="eventIconText">
									<span>
										<i className="fa-solid fa-location-dot"></i>
									</span>
									Venue
								</p>
								<p>{event.venue.name}</p>
								<p>{event.venue.address}</p>
							</div>
						)}
					</div>

					<div>
						{event.speaker && (
							<div>
								<p className="eventIconText">
									<span>
										<i className="fa-solid fa-microphone-lines"></i>
									</span>
									Speaker
								</p>
								<p>{event.speaker.name}</p>
								<p>{event.speaker.bio}</p>
							</div>
						)}
					</div>

					<div>
						{event.tickets && (
							<div>
								<h3>Tickets</h3>
								<Link to={event.tickets}>Buy Tickets</Link>
							</div>
						)}
					</div>
				</div>
				{timeLeft && (
					<div className="countdownContainer">
						<h2>Countdown to Event</h2>
						<div className="countdownCard">
							<div className="timeLeftContainer">
								<p className="timeLeft">{timeLeft.days}</p>
								<p>Days</p>
							</div>
							<div className="timeLeftContainer">
								<p className="timeLeft">{timeLeft.hours}</p>
								<p>Hours</p>
							</div>
							<div className="timeLeftContainer">
								<p className="timeLeft">{timeLeft.minutes}</p>
								<p>Minutes</p>
							</div>
							<div className="timeLeftContainer">
								<p className="timeLeft">{timeLeft.seconds}</p>
								<p>Seconds</p>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default EventDetail;

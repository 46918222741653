import mailIcon from "../../assets/mailIcon.svg";
import callIcon from "../../assets/callIcon.svg";

function ContactCard() {
	return (
		<div className="contactCard">
			<div className="contactCardTexts">
				<p className="heading3">Get In Touch</p>
				<p className="contactCardText">
					Email, call, or complete the form to connect with us.
				</p>
			</div>
			<div className="emailTab">
				<span>
					<img src={mailIcon} alt="Mail Icon" className="mailIcon" />
				</span>
				<div>
					<p className="emailTitle">Email</p>
					<a href="mailto:info.gcasregina@gmail.com">
						<p>info.gcasregina@gmail.com</p>
					</a>
				</div>
			</div>
			<div className="phoneTab">
				<span>
					<img src={callIcon} alt="Call Icon" className="callIcon" />
				</span>
				<a href="tel:+13065022466">
					<p>+1 (306) 502 2466</p>
				</a>
			</div>
		</div>
	);
}

export default ContactCard;

function Team({ name, position, profileImg }) {
	return (
		<>
			<div className="teamCard">
				<img className="profileImg" src={profileImg} alt="Profile Pic" />
				<div className="nameTag">
					<p className="name">{name}</p>
					<p className="position">{position}</p>
				</div>
			</div>
		</>
	);
}

export default Team;

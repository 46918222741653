import coreValue1 from "./assets/images/coreValue1.png";
import coreValue2 from "./assets/images/coreValue2.png";
import coreValue3 from "./assets/images/coreValue3.png";

const coreValuesList = [
	{
		title: "Unity and Support",
		info: "At GCAS, we believe in the power of community. We actively work to strengthen relationships among Ghanaians and Canadians in Saskatchewan through various programs and initiatives. Our goal is to create a network of mutual support where members can rely on one another for assistance, guidance, and friendship. ",
		img: coreValue1,
	},
	{
		title: "Preserving the Cultural Heritage ",
		info: "Our association is deeply committed to preserving the rich cultural heritage of Ghana. We celebrate Ghanaian culture through various cultural events, language classes, traditional music and dance workshops, and heritage celebrations. By maintaining these traditions, we ensure that our heritage is passed down to younger generations and shared with the broader community, fostering a deeper understanding and appreciation of Ghanaian culture. ",
		img: coreValue2,
	},
	{
		title: "Facilitating Integration",
		info: "Moving to a new country can be challenging. GCAS is dedicated to assisting new Ghanaian immigrants in their settlement and integration into Saskatchewan society. We offer a range of support services, including orientation programs, mentorship, language assistance, and help with navigating local services and systems. Our goal is to make the transition as smooth as possible, helping newcomers feel at home and become active, contributing members of the community.",
		img: coreValue3,
	},
];

export default coreValuesList;

import { Link } from "react-router-dom";
import Carousel from "./subComponents/carousel";
function About() {
	return (
		<section className="desktopMaxWidth aboutSection">
			<div className="aboutUs">
				<div className="aboutText">
					<h2>About Us</h2>
					<p className="welcomeText">
						Welcome to the Ghanaian-Canadian Association of Saskatchewan (GCAS),
						a vibrant and inclusive community organization based in Regina
						Saskatchewan.
					</p>
				</div>
				<Link to="/about" className="aboutLink">
					<button aria-label="Learn More" className="cta aboutCta">
						More about GCAS
						<span>
							<i className="fa-solid fa-chevron-right"></i>
						</span>
					</button>
				</Link>
			</div>

			<Carousel />
		</section>
	);
}

export default About;

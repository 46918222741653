import React, { useEffect, useRef } from "react";
import Hero from "../components/Hero";
import About from "../components/About";
import Event from "../components/Event";
import Contact from "../components/Contact";

function Home() {
	const sectionsRef = useRef([]);

	useEffect(() => {
		const currentSections = sectionsRef.current;

		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						entry.target.classList.add("visible");
					}
				});
			},
			{ threshold: 0.1 } // Adjust the threshold as needed
		);

		currentSections.forEach((section) => {
			if (section) observer.observe(section);
		});

		return () => {
			currentSections.forEach((section) => {
				if (section) observer.unobserve(section);
			});
		};
	}, []);

	return (
		<section className="homeContainer">
			<div ref={(el) => (sectionsRef.current[0] = el)} className="animate">
				<Hero />
			</div>
			<div ref={(el) => (sectionsRef.current[1] = el)} className="animate">
				<About />
			</div>
			<div ref={(el) => (sectionsRef.current[2] = el)} className="animate">
				<Event />
			</div>
			<div ref={(el) => (sectionsRef.current[3] = el)} className="animate">
				<Contact />
			</div>
		</section>
	);
}

export default Home;

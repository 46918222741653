import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import "swiper/css/effect-fade";
import "swiper/css/bundle";
import slide1 from "../../assets/images/slide1.png";
import slide2 from "../../assets/images/slide2.png";
import slide3 from "../../assets/images/slide3.png";

function Carousel() {
	return (
		<div className="swiperContainer">
			<Swiper
				modules={[EffectFade, Autoplay]}
				spaceBetween={30}
				centeredSlides={true}
				autoplay={{
					delay: 5000,
					disableOnInteraction: true,
				}}
				effect={"fade"}
				loop
				slidesPerView={3}
			>
				<SwiperSlide>
					<img src={slide1} alt="slide1" />
				</SwiperSlide>
				<SwiperSlide>
					<img src={slide2} alt="slide2" />
				</SwiperSlide>
				<SwiperSlide>
					<img src={slide3} alt="slide3" />
				</SwiperSlide>
			</Swiper>
		</div>
	);
}

export default Carousel;

function Footer() {
	return (
		<footer>
			<nav className="footerNavBar">
				<a className="logo" href="/">
					GCAS <span>Regina</span>
				</a>
				<ul className="navItems">
					<li>
						<a className="footerNavItem" href="/privacy-policy">
							Privacy Policy
						</a>
					</li>
					<li>
						<a className="footerNavItem" href="/terms-and-conditions">
							Terms and Conditions
						</a>
					</li>
				</ul>
				<div className="socials">
					<a
						href="https://www.instagram.com/yourprofile"
						target="_blank"
						rel="noopener noreferrer"
						aria-label="Visit our Instagram profile"
					>
						<i className="fa-brands fa-instagram"></i>
					</a>
					<a
						href="https://wa.me/yourphonenumber"
						target="_blank"
						rel="noopener noreferrer"
						aria-label="Chat with us on WhatsApp"
					>
						<i className="fa-brands fa-whatsapp"></i>
					</a>
					<a
						href="https://www.facebook.com/yourpage"
						target="_blank"
						rel="noopener noreferrer"
						aria-label="Visit our Facebook page"
					>
						<i className="fa-brands fa-facebook"></i>
					</a>
				</div>
			</nav>
			<hr />
			<p className="copyright">
				&copy; Copyright 2024 | All Rights Reserved | Lacunity Studios &trade;
			</p>
		</footer>
	);
}

export default Footer;
